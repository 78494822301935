<template>
  <div class="pages">
    <el-pagination
      background
      class="work_pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="page.pageNum"
      :page-size="page.pageSize"
      layout="prev, pager, next, total"
      :total="20"
    ></el-pagination>
  </div>
  <!-- :prev-text="pagination.pre"
  :next-text="pagination.next"-->
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      //   pagination: {
      //     pre: "上一页",
      //     next: "下一页",
      //   },
      page: {
        pageNum: 1,
        pageSize: 6,
      },
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page.pageNum = val;
      //   this.getPostlist();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      //   this.getPostlist();
    },
  },
};
</script>

<style>
</style>