<template>
  <div class="article">
    <el-form :model="form" :rules="err" ref="ruleForm" label-width="100px" class="form">
      <el-form-item label="标题：" prop="name">
        <el-input v-model="form.name" placeholder="请输入标题" class="w400"></el-input>
      </el-form-item>
      <el-form-item label="配图" prop="reamark1" class="avatar">
        <cd-upload v-model="form.reamark1" :isLogo="false"
                   :on-success="handleAvatarimageurl"/>
      </el-form-item>
      <el-form-item label="分类：" v-if="type == 1">
        <p>KOL就创业故事</p>
      </el-form-item>
      <el-form-item label="分类：" prop="fenclass" v-else>
        <el-select
            v-model="form.fenclass"
            class="selects"
            placeholder="请选择分类"
            popper-class="select_link"
        >
          <el-option
              label="求职"
              value="1"
          >
            求职
          </el-option>
          <el-option
              label="心理"
              value="2"
          >
            心理
          </el-option>
          <el-option
              label="婚恋"
              value="3"
          >
            婚恋
          </el-option>
          <el-option
              label="创业"
              value="4"
          >
            创业
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关键词：" prop="keyWord">
        <el-input v-model="form.keyWord" placeholder="请输入关键词" class="w400"></el-input>
      </el-form-item>
      <el-form-item label="文章内容：" prop="content">
        <edit
            placeholder="请输入文章内容"
            class="w430"
            :testcontent="form.content"
            @html="hanContent"
        ></edit>
      </el-form-item>
      <div class="button">
        <el-button type="info" @click="handleClose()">取消</el-button>
        <el-button type="primary" @click="kolsaveArticle()">{{ text }}</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import edit from "@/components/public/editor2"
import MulSelect from "@/components/selectComponents/MulSelect";

export default {
  name: "articleForm",
  props: ["type", "id"],
  inject: ["reload"],
  components: {edit, MulSelect},
  data() {
    return {
      form: {
        name: '',
        reamark1: '',
        fenclass: '',
        keyWord: '',
        content: '',
      },
      err: {
        name: [
          {required: true, message: '请输入标题', trigger: 'blur'}
        ],
        fenclass: {
          required: true, message: '请选择', trigger: 'change',
        },
        keyWord: [
          {required: true, message: '请输入关键词', trigger: 'blur'}
        ],
        content: {
          required: true, message: '请发布文章内容', trigger: 'change',
        },
      },
      text: '发布',
    }
  },
  methods: {
    // 发布kol文章
    kolsaveArticle() {
      if (this.type == 1) {
        this.form.fenclass = 5;
      }
      this.$api.kolsaveArticle(this.form).then((res) => {
        if (res.data.success) {
          this.$message.success(res.data.msg);
          this.reload();
          this.handleClose();
        } else {
          this.$message.error(res.data.msg)
        }
        console.log(res.data)
      })
    },
    //获取富文本的内容
    hanContent(val) {
      console.log(val)
      this.form.content = val;
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    handleClose() {
      this.$emit('close', false);
    },
    handleAvatarimageurl(res, file) {
      this.form.reamark1 = res.msg;
    },
    erroupload() {
      this.$message({
        message: "图片上传失败，请重新上传！！",
        center: true,
      });
    },
    beforeAvatarUpload(file) {
      let type = ["image/jpeg", "image/png"];
      const isImage = type.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error("上传图片格式不正确");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      console.log(isImage, isLt2M)
      return isImage && isLt2M;
    },
    async getArticleDetail() {
      this.text = "保存";
      let res = await this.$api.kolgetArticleDetail({id: this.id}).then((res) => {
        if (res.data.success) {
          this.form = res.data.data;
        }
      })
    },
  },
  mounted() {
    if (this.id) {
      this.getArticleDetail();
    }
  },
}
</script>

<style scoped lang="less">
.avatar-uploader {
  position: relative;
  width: 178px;
  height: 178px;
  //border: 1px solid #ddd;
  background: #f9f9f9;
  border-radius: 6px;
  cursor: pointer;
  //overflow: hidden;
  .el-upload.el-upload--text {
    display: block !important;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .el-icon-plus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #00924b;
  }
}

p {
  color: #666666;
  font-size: 12px;
}

.button {
  padding: 20px 0;
  text-align: center;

  .el-button {
    width: 180px;
  }
}
</style>
