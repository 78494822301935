<template>
  <div class="wrapper">
    <div id="column" class="world">
      <ul class="tab flex">
        <li
            v-for="(item, index) in tab"
            :key="index"
            :class="{ active: active == index }"
            @click="tabs(index)"
        >
          {{ item }}
        </li>
      </ul>
      <!-- 我评论的 -->
      <section
          v-show="active == '0'"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          class="loads"
      >
        <div v-for="item in commentList" :key="item.id">
          <p class="ptext line_clamp3">
            <span>我：</span>
            {{ item.content }}
          </p>
          <div class="bgs">
            <div class="txt cursorP">
              <h4>{{ item.spcialClomunInfo.name }}</h4>
              <p class="text line_clamp3">
                {{ excision(item.spcialClomunInfo.gaidesc) }}
                <router-link
                    :to="{
                    path: '/section/sectionDetail',
                    query: { id: item.spcialClomunInfo.id },
                  }"
                    :title="item.spcialClomunInfo.name"
                    target="_blank"
                >
                  <span class="more">阅读全文</span>
                </router-link>
              </p>
            </div>
            <div class="feature">
              <ul class="flex left">
                <li>
                  <i class="el-icon-view"></i>
                  {{ item.spcialClomunInfo.clicks }} 阅读
                </li>
                <li>
                  <i class="el-icon-chat-dot-square"></i>
                  {{ item.spcialClomunInfo.jsonObject.recomands }} 评论
                </li>
                <li
                    class="praise"
                    :class="
                    item.spcialClomunInfo.userInfo.goods == '0' ? '' : 'success'
                  "
                    @click="collectCloumn(item.id, item.spcialClomunInfo)"
                >
                  <img src="~/static/ask/like.png" alt class="img"/>
                  <img src="~/static/ask/liked.png" alt class="green"/>
                  {{ item.spcialClomunInfo.jsonObject.goods }} 点赞
                </li>
              </ul>
              <p>#{{ item.spcialClomunInfo.fenclass }}</p>
            </div>
          </div>
          <div class="time marT10 color99 fr">
            {{ item.createDate }}
          </div>
          <el-divider></el-divider>
        </div>
        <div v-if="active == '0' && status.isHide == 1">
          <status :type="status.type" :des="status.des"></status>
        </div>
      </section>
      <!-- 我关注的 -->
      <section
          v-show="active == '1'"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          class="loads"
      >
        <div v-for="item in focusList" :key="item.id">
          <div class="flex">
            <div class="top flex">
              <div class="imgs flexs">
                <img
                    :src="item.avatar"
                    alt
                    :title="item.realname"
                    class="err_avatar"
                />
              </div>
              <div class="rights">
                <p class="p1">{{ item.realname }}</p>
                <p class="p2">{{ item.breifdescrition }}</p>
              </div>
            </div>
            <div @click="look(item.id)">
              <el-button :type="focus == 0 ? '' : 'primary'" size="mini" round>
                <span>{{ focus == 0 ? "取消关注" : "关注" }}</span>
              </el-button>
            </div>
          </div>
          <el-divider></el-divider>
        </div>
        <div v-if="active == '1' && status.isHide == 2">
          <status :type="status.type" :des="status.des"></status>
        </div>
      </section>
      <!-- 我点赞的 -->
      <section
          v-show="active == '2'"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          class="loads"
      >
        <Like :goodList="goodList" @collectCloumns="collectCloumns"></Like>
        <div v-if="active == '2' && status.isHide == 3">
          <status :type="status.type" :des="status.des"></status>
        </div>
      </section>
      <!-- 我发布的专栏文章 -->
      <ul
          v-show="active == '3'"
          class="list loads"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
      >
        <template>
          <el-table :data="columnArticleList" align="center" style="width: 100%">
            <el-table-column type="index" label="序号" align="center" width="140">
            </el-table-column>
            <el-table-column
                prop="name"
                label="标题"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="fenclass"
                label="类型"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="keyWord"
                label="关键字"
                align="center"
            ></el-table-column>
            <el-table-column prop="address" align="center" label="操作">
              <template slot-scope="scope">
                <el-button @click="openKol(0,scope.row.id)" type="text"
                >编辑
                </el-button
                >
                <el-button
                    @click.stop="koldelArticle(scope.row.id)"
                    type="text"
                >删除
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </ul>
      <!-- 我发布的KOL文章 -->
      <ul
          v-show="active == '4'"
          class="list loads"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
      >
        <template>
          <el-table :data="columnArticleList" style="width: 100%">
            <el-table-column type="index" align="center" label="序号" width="140">
            </el-table-column>
            <el-table-column
                prop="name"
                label="标题"
                align="center"

            ></el-table-column>
            <el-table-column
                prop="keyWord"
                label="关键字"
                align="center"
            ></el-table-column>
            <el-table-column align="center" prop="address" label="操作">
              <template slot-scope="scope">
                <el-button @click="openKol(1,scope.row.id)" type="text"
                >编辑
                </el-button
                >
                <el-button
                    @click.stop="koldelArticle(scope.row.id)"
                    type="text"
                >删除
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </ul>
      <!-- 分页 -->
      <div class="pages">
        <el-pagination
            background
            class="work_pagination"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="params.pageNum"
            :page-size="params.pageSize"
            layout="prev, pager, next, total"
            :total="total"
        ></el-pagination>
      </div>
      <!-- 编辑文章弹窗 -->
      <el-dialog
          title="KOL文章发布"
          :visible.sync="dialogVisible"
          v-if="dialogVisible"
          width="580px"
          :before-close="handleClose">
        <articleForm :type="isType" :id="kolId" @close="handleClose"></articleForm>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Like from "components/center/like";
import Pagination from "components/public/Pagination";
import articleForm from "@/components/community/articleForm";

export default {
  name: "column",
  components: {Like, Pagination, articleForm},
  data() {
    return {
      columnArticleList: [],
      kolAticleList: [],
      dialogVisible: false,
      isType: "1",
      kolId: '',
      active: 0,
      focus: false,
      loading: false,
      tab: ["我评论的", "我关注的", "我点赞的", "我的专栏文章", "我的KOL文章"],
      total: 0,
      status: {
        isHide: 0,
        type: "",
        des: "",
      },
      commentList: [],
      focusList: [],
      goodList: [],
      params: {
        pageNum: 1,
        pageSize: 8,
        fenclass: 1,
      },
    };
  },
  methods: {
    tabs(i) {
      this.active = i;
      console.log(this.active);
      if (this.active == "0") {
        this.queryCommentClomun();
      }
      if (this.active == "1") {
        this.queryFocusClomun();
      }
      if (this.active == "2") {
        this.queryGoodClomun();
      }
      if (this.active == "3" || this.active == "4") {
        this.getArticleList();
      }
    },
    async queryCommentClomun() {
      this.loading = true;
      try {
        let res = await this.$api.queryCommentClomun(this.params);
        console.log("我评论的", res);
        if (res.data.success) {
          this.commentList = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.commentList.length <= 0) {
            this.status.isHide = 1;
            this.status.type = "post";
            this.status.des = "暂无评论数据！";
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async queryFocusClomun() {
      try {
        this.loading = true;
        let res = await this.$api.queryFocusClomun(this.params);
        console.log("我关注的", res);
        if (res.data.success) {
          this.focusList = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.focusList.length <= 0) {
            this.status.isHide = 2;
            this.status.type = "post";
            this.status.des = "暂无关注数据！";
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async queryGoodClomun() {
      try {
        this.loading = true;
        let res = await this.$api.queryGoodClomun(this.params);
        console.log("我点赞的", res);
        if (res.data.success) {
          this.goodList = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.goodList.length <= 0) {
            this.status.isHide = 3;
            this.status.type = "post";
            this.status.des = "暂无点赞数据！";
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 我的专栏文章
    async getArticleList() {
      try {
        this.loading = true;
        if (this.active == 3) {
          this.params.fenclass = 1;
        } else if (this.active == 4) {
          this.params.fenclass = 5;
        }
        let res = await this.$api.getArticleList(this.params);
        if (res.data.success) {
          this.columnArticleList = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.goodList.length <= 0) {
            this.status.isHide = 3;
            this.status.type = "post";
            this.status.des = "暂无专栏文章！";
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 我的KOL文章
    // 删除文章
    koldelArticle(id) {
      this.$confirm("确定删该文章吗?一旦删除不可恢复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$api.koldelArticle({id: id}).then((res) => {
              if (res.data.success) {
                this.$message.success(res.data.msg);
                this.getArticleList();
              } else {
                this.$message.error(res.data.msg)
              }
            })
          })
          .catch((err) => {
            console.log(err);
          });

    },
    // 打开编辑文章弹窗
    openKol(type, id) {
      this.isType = type;
      this.kolId = id;
      this.dialogVisible = true;
    },
    // 关闭编辑文章弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    // 控制字体长度
    excision(text) {
      if (text) {
        if (text.length > 280) {
          return text.slice(0, 280) + "...";
        } else {
          this.more = 3;
        }
        return text;
      }
    },
    // 点赞
    collectCloumn(id, info) {
      this.$api.collectCloumnApi({id: id}).then((res) => {
        var goods = Number(info.jsonObject.goods);
        info.userInfo.goods = 1 ^ info.userInfo.goods;
        if (info.userInfo.goods) {
          info.jsonObject.goods = Number(info.jsonObject.goods) + 1;
          this.$message.success(res.data.msg);
          this.queryGoodClomun();
        } else {
          info.jsonObject.goods -= 1;
          this.$message.error(res.data.msg);
          this.queryGoodClomun();
        }
      });
    },

    collectCloumns(index) {
      let info = this.goodList[index];
      console.log(index, info);
      this.collectCloumn(info.id, info);
    },
    //关注
    look(id) {
      this.$api.focusAuthorApi({id: id}).then((res) => {
        console.log(res);
        if (res.data.success) {
          console.log(res);
          this.focus = 1 ^ this.focus;
          console.log(this.focus);
          if (this.focus) {
            this.$message.success(res.data.msg);
            this.focus = 0;
          } else {
            this.$message.error(res.data.msg);
          }
          this.queryFocusClomun();
        }
      });
    },

    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },

    //分页
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.tabs(this.active);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.tabs(this.active);
    },
  },
  created() {
    this.tabs(this.active);
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/center/center.less";

.wrapper {
  background: #ffffff;
}

/deep/ .el-loading-spinner {
  top: 30%;
}

.more {
  color: #00924c;
  text-decoration: underline;
  font-size: 14px;
}

/deep/ .el-loading-mask {
  top: 28px;
  right: 70px;
}

/deep/ .el-loading-spinner {
  top: 5%;
}

.pages {
  padding-top: 90px;
}
</style>
